
import './App.css';
import Invitation from './components/Invitation';

function App() {
  return (
    <div className="App">
      <Invitation />


    </div>
  );
}

export default App;
