import Date from "./Date/Date";
import Scroller from "./Scroller/Scroller";
import Hotel from "./Hotel/Hotel";
import "./Invitation.css";
import brideandgroom from "../../src/images/brudeparet.png";
import brideandgroom3 from "../../src/images/brudeparet3.png";
import footerphoto from "../../src/images/brudeparetstort.png";

const Invitation = () => {


    return (

        <>
            <div>
                <p className="sub-header">Du/ dere er herved invitert til vårt bryllup</p>
                <p className="title">Kristine & Gaute</p>

                <Date />

                <div>
                    <p className="address">Opus XVI <br></br>
                        Vågsallmenningen 16, 5014 Bergen</p>
                    <Scroller />
                    <p className="rsvp">Svar utbes / 01.05.2023</p>
                </div>

                <img className="brideandgroom" src={brideandgroom} alt="Brudeparet" loading="lazy"></img>

                <div className="toastmaster-container">
                    <p className="title-toastmaster">Toastmaster</p>
                    <p className="name-toastmaster">Robin Sparre</p>
                    
                    <p className="toastmaster-contactinfo">
                        Kontaktinfo: <br></br>
                        Tlf: 995 70 547 <br></br>
                        E-post: robin.sparre@gmail.com
                    </p>
                </div>
                <p className="side-note">
                    Dersom du ønsker å holde tale
                    i bryllupet, vennligst kontakt vår toastmaster.
                </p>
            </div>
            <div>

                <Hotel />

                <div className="thenightbefore">
                    <img className="brideandgroom3" src={brideandgroom3} alt="Brudeparet susser
                        " loading="lazy"></img>
               </div>
                <p className="side-note allergies">
                     Send ditt/ deres svar til Gaute på tlf 99099989 <br></br><br></br>
                    Dersom du har allergener eller ønsker vegetar eller vegansk, vennligst gi beskjed sammen med svar.
                </p>

                <img className="footerphoto" src={footerphoto} alt="Brudeparet" loading="lazy"></img>
            </div>



        </>

    )
}

export default Invitation;