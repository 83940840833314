import rings from "../../images/rings.png";
import wine from "../../images/wine.png";
import dinner from "../../images/dinner.png";
import cake from "../../images/cake.png";
import disco from "../../images/disco.png";
import sparkler from "../../images/sparklers.png";
import arrow from "../../images/arrow.png";



import "./Scroller.css";
const Scroller = () => {
    return (
        <>

            <div className="container">
                <div className="grid">
                    <div className="item rings">
                        <img className="rings" src={rings} alt="Ringer" loading="lazy"></img>
                        <span className="scroller-time rings">15:00</span>
                        <p className="scroller-title rings">Vielse</p>

                    </div>
                    <div className="item">
                        <img className="wine" src={wine} alt="Vin- og champagneglass" loading="lazy"></img>
                        <span className="scroller-time">17:30</span>
                        <p className="scroller-title">Aperitiff</p>
                    </div>
                    <div className="item">
                    <img className="arrow" src={arrow} alt="pil" loading="lazy"></img>
                    </div>
                    <div className="item">
                        <img className="dinner" src={dinner} alt="Middag" loading="lazy"></img>
                        <span className="scroller-time">18:00</span>
                        <p className="scroller-title">Middag</p>

                    </div>
                    <div className="item">
                        <img className="cake" src={cake} alt="bryllupskake" loading="lazy"></img>
                        <span className="scroller-time">21:30</span>
                        <p className="scroller-title">Kake</p>

                    </div>

                    <div className="item">
                        <img className="party" src={disco} alt="discokule" loading="lazy"></img>
                        <span className="scroller-time">22:00</span>
                        <p className="scroller-title">Fest</p>

                    </div>
                    <div className="item sparkler">

                        <img className="sparkler" src={sparkler} alt="stjerneskudd" loading="lazy"></img>
                        <span className="scroller-time sparkler">02:00</span>
                        <p className="scroller-title sparkler">Slutt</p>
                    </div>

                </div>

                <div className="party-info">
                    <p>Vielse klokken tre
                        <br></br> Aperitiff klokken halv seks
                        <br></br>Middag klokken seks
                    </p>
                </div>
            </div>

        </>

    )
}



export default Scroller;
