import "./Date.css"
const Date = () => {


    return (

        <>
            <div className="Date">
            <p className="Date-time">Lørdag, første juli <br></br>
                Tjue Tjuetre <br></br>
                Klokken tre på ettermiddagen
            </p>

            </div>
        </>

    )
}

export default Date;