import "./Hotel.css"
import brideandgroom2 from "../../images/brudeparet2.png";

const Hotel = () => {


    return (

        <>
            <div className="Hotel">
                    <div className="background-box">
                        <p className="title-hotel">Overnatting</p>
                    </div>
                    <div className="image-container">
                        <img className="brideandgroom2" src={brideandgroom2} alt="Brudeparet i en trapp
                        " loading="lazy"></img>
                    </div>

                    <div className="discount">
                        <p className="title-discount">Rabattlenke:</p>
                        <p className="discount-info">
                            Dersom dere ønsker å booke overnatting ved hotellet, bruk følgende lenke:
                            <br></br>
                        <br></br>
                            <a href="https://www.mews.li/distributor/023ed01d-d39a-4ff4-9b1c-acaa00735066?mewsVoucherCode=Kristine%20%26%20Gaute%20%7C%201.%20juli%202023&fbclid=IwAR2ysd_kjrIkQskfW7WCif7WulKdFQMcTm1xOjqCgzUALxgBErpW0e5AnZ4" className="a">Opus XVI</a>
                            <br></br>
                            <br></br>
                            PS: hotellet nærmer seg fullbooket.
                            </p>
                    
            </div>
            </div>
        </>

    )
}

export default Hotel;